import React, { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loadable from "../layouts/full/shared/loadable/Loadable";

const FullLayout = Loadable(lazy(() => import("../layouts/full/FullLayout")));
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank/BlankLayout")),
);

const Attendance = Loadable(
  lazy(() => import("../views/Attendance/Attendance")),
);
const StudentForm = Loadable(
  lazy(() => import("../views/StudentForm/StudentForm")),
);
const StudentList = Loadable(
  lazy(() => import("../views/StudentList/StudentList")),
);

const StaffForm = Loadable(lazy(() => import("../views/StaffForm/StaffForm")));
const StaffList = Loadable(lazy(() => import("../views/StaffList/StaffList")));

const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const Login = Loadable(lazy(() => import("../views/authentication/Login")));

// Admin Routes
const AdminSchoolsList = Loadable(
  lazy(() => import("../views/Admin/SchoolsList/SchoolsList")),
);
const AdminDashboard = Loadable(
  lazy(() => import("../views/Admin/Dashboard/Dashboard")),
);
const AdminSchoolInfo = Loadable(
  lazy(() => import("../views/Admin/SchoolInfo/SchoolInfo")),
);
const AdminAttSummary = Loadable(
  lazy(() => import("../views/Admin/AttSummary/AttSummary")),
);

function Router() {
  let Data = localStorage.getItem("@School");
  if (Data) {
    Data = JSON.parse(Data);
  } else
    Data = {
      level: 1,
    };
  const Routing = useRoutes([
    {
      path: "/",
      element: <FullLayout />,
      children: [
        {
          path: "/",
          element: (
            <Navigate
              to={Data.level < 3 ? "/attendance" : "/admin/dashboard"}
            />
          ),
        },
        { path: "/attendance", exact: true, element: <Attendance /> },
        { path: "/student-form", exact: true, element: <StudentForm /> },
        { path: "/student-list", exact: true, element: <StudentList /> },
        { path: "/staff-form", exact: true, element: <StaffForm /> },
        { path: "/staff-list", exact: true, element: <StaffList /> },

        // Admin
        {
          path: "/admin/dashboard",
          exact: true,
          element: <AdminDashboard />,
        },
        {
          path: "/admin/schools-list",
          exact: true,
          element: <AdminSchoolsList />,
        },
        // {
        //   path: "/admin/attendance",
        //   exact: true,
        //   element: <AdminAttSummary />,
        // },
        {
          path: "/admin/school-info/:emis",
          exact: true,
          element: <AdminSchoolInfo />,
        },

        { path: "*", element: <Navigate to="/auth/404" /> },
      ],
    },

    {
      path: "/auth",
      element: <BlankLayout />,
      children: [
        { path: "404", element: <Error /> },
        { path: "/auth/login", element: <Login /> },
        { path: "*", element: <Navigate to="/auth/404" /> },
      ],
    },
  ]);
  return Routing;
}

export default Router;
